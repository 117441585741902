import { Col, Flex, Form, notification, Row, Space } from "antd";
import { useWatch } from "antd/es/form/Form";
import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import CommonButton from "../../../components/Common/Button";
import CommonConfirmModal from "../../../components/Common/ConfirmModal";
import CommonForm from "../../../components/Common/Form";
import CommonFormItem from "../../../components/Common/FormItem";
import CommonSelect from "../../../components/Common/Select";
import journeyServices from "../../../services/journeyManagement.service";
import lockServices from "../../../services/lockManagement.service";
import vehicleTypeServices from "../../../services/vehicleTypeCategory.service";
import driverCategory from "../../../services/driverCategory.service";
import {
  DATE_TIME_FORMAT_SECOND,
  REGEX_CODE,
  REGEX_NUMBER_GREATER_ZERO,
  REGEX_ONLY_NUMBER,
  ROLE_LIST,
  SEV_CODE,
} from "../../../utils/constans";
import { JOURNEY_STATUS_VALUES, OPTION_DELIVERY } from "../../../utils/constans/journeyManagement";
import { SIDEBAR_ITEM_HREF } from "../../../utils/constans/sidebar";
import { ICommonSelectDataType } from "../../../utils/types";
import {
  ICompanyDeliverySites,
  ICompanyDeliverySitesAddData,
  IJourneyDetailData,
  IRegisterCarLocks,
} from "../../../utils/types/journeyManagement";
import { ILockDetailData } from "../../../utils/types/lockManagement";
import { IVehicleTypeDetailData } from "../../../utils/types/vehicleType";
import { isHavePermission } from "../../../utils/utilFunctions";
import JourneyTabs from "./JourneyTabs";
// Sử dụng context lưu trữ dữ liệu
import DataUpdateContext from "./context";
import { IDriverDetailData } from "../../../utils/types/driverCategory";

function VehicleInfoDetail() {
  const componentPath = SIDEBAR_ITEM_HREF.journey_management;
  const { t } = useTranslation();
  const params = useParams();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);
  const [isLoadingAttachLock, setIsLoadingAttachLock] = useState<boolean>(false);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState<boolean>(false);
  const [isLoadingReportLock, setIsLoadingReportLock] = useState<boolean>(false);
  const [isLoadingDismantlingLock, setIsLoadingDismantlingLock] = useState<boolean>(false);
  const [visibleDismantlingLock, setVisibleDismantlingLock] = useState<boolean>(false);
  const [visibleReportLock, setVisibleReportLock] = useState<boolean>(false);
  const [visibleConfirm, setVisibleConfirm] = useState<boolean>(false);
  const [typeCheckConfirm, setTypeCheckConfirm] = useState<number>(); // 1: check-in , 2: check-out , 3: khởi hành, 4: hoàn thành,5: hủy hành trình
  const [selectIMEIData, setSelectIMEIData] = useState<ICommonSelectDataType[]>([]);
  const [selectVehicleData, setSelectVehicleData] = useState<ICommonSelectDataType[]>([]);
  const [driverSelectData, setDriverSelectData] = useState<IDriverDetailData[]>([]);
  const [idLockReport, setIdLockReport] = useState<number>();
  const [idLockDismantling, setIdLockDismantling] = useState<number>();
  const [dataDetail, setDataDetail] = useState<IJourneyDetailData>();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { profile } = useSelector((state: any) => state?.profileReducer);
  const isSEV = profile?.source === SEV_CODE;
  const isCanEditInfo = isHavePermission(ROLE_LIST.QLHT_EDIT, profile);
  const isCanViewDetailInfo = isHavePermission(ROLE_LIST.QLHT_VIEW_DETAIL_CAR, profile);
  const isCanEditDeli = isHavePermission(ROLE_LIST.QLHT_EDIT_POSITION, profile);
  const isCanAttachLock = isHavePermission(ROLE_LIST.QLHT_ADD_IMEI, profile);
  const isCanReportLock = isHavePermission(ROLE_LIST.QLHT_REPORT_LOCK, profile);
  const isCanCancel = isHavePermission(ROLE_LIST.QLHT_CANCEL, profile);
  const isCanCheckin = isHavePermission(ROLE_LIST.QLHT_CHECKIN_CHECKOUT, profile);
  // Thêm dữ liệu
  const [dataUpdate, setDataUpdate] = useState<{ [key: string]: any }>({});
  // Check khu hiện tại
  const [currentAreaCheck, setCurrentAreaCheck] = useState<{ name: string; action: string }>({
    name: "",
    action: "",
  });
  const [nameAreaFocus, setNameAreaFocus] = useState<string>("");
  // Thêm lý do huỷ chuyến, cập nhật chuyến và di chuyển trễ
  const [reasonCancel, setReasonCanel] = useState<string>("");
  const [reasonUpdate, setReasonUpdate] = useState<string>("");
  const [reasonDelay, setReasonDelay] = useState<string>("");
  // Xác định hành trình có thể chỉnh sửa hay không
  const isDisableField =
    !(
      dataDetail?.source === profile?.source &&
      ((!isSEV && dataDetail?.status === JOURNEY_STATUS_VALUES.KHOI_TAO) ||
        (isSEV && dataDetail?.status === JOURNEY_STATUS_VALUES.CHUA_VAO_SEV))
    ) ||
    !isCanEditInfo ||
    !isEditing;
  const isDisableVehicleType =
    !(
      dataDetail?.source === profile?.source &&
      isSEV &&
      dataDetail?.status === JOURNEY_STATUS_VALUES.CHUA_VAO_SEV
    ) ||
    !isCanEditInfo ||
    !isEditing;

  const totalDoorValue = useWatch("totalDoor", form);
  // Mỗi 5s sẽ load lại dữ liệu 1 lần
  // Xác định load lần đầu và những lần sau không hiển thị loadding
  let isFirtLoad = false;

  const getDataSelect = useCallback(async () => {
    const paramsSearch = {
      page: 0,
      size: 1000,
    };

    //select loại xe
    const respVehicle = await vehicleTypeServices.getListVehicleType({
      ...paramsSearch,
      search: JSON.stringify({}),
    });
    const dataVehicle = respVehicle?.data;
    if (respVehicle?.status === 200) {
      setSelectVehicleData(
        dataVehicle?.data?.content?.map((item: IVehicleTypeDetailData) => ({
          value: item?.id,
          label: item?.name,
        })),
      );
    } else {
      setSelectVehicleData([]);
    }

    // select tài xế
    const respDriverCategory = await driverCategory.getListDriver({
      ...paramsSearch,
      search: JSON.stringify({}),
    });
    const dataDriverCategory = respDriverCategory?.data;
    if (respDriverCategory?.status === 200) {
      // Thêm module quản lý tài xế, khi trước nhập thủ công tên và CCCD tài xế
      // Giờ thêm nên phát sinh case nếu tài xế này bị xóa hoặc tồn đọng từ phiên bản trước
      // nhưng lịch sử vẫn ghi nhận nên thêm một bản ghi ảo để chọn
      if (
        JSON.stringify(
          dataDriverCategory?.data?.content.find(
            (value: IDriverDetailData) => value.uniqueid === form.getFieldValue("driverIdentity"),
          ),
        )
      ) {
        setDriverSelectData(dataDriverCategory?.data?.content);
      } else {
        setDriverSelectData([
          ...dataDriverCategory?.data?.content,
          {
            id: 0,
            isDelete: 0,
            createdDate: 0,
            modifiedDate: 0,
            createBy: "string",
            modifiedBy: "string",
            createdDateStr: "string",
            modifiedDateStr: "string",
            createById: 0,
            modifiedById: 0,
            name: form.getFieldValue("driverName"),
            uniqueid: form.getFieldValue("driverIdentity"),
            countUsed: 0,
          },
        ]);
      }
    } else {
      setDriverSelectData([]);
    }
  }, []);

  const getDataDetail = useCallback(async () => {
    // setIsLoading(true);
    if (!isFirtLoad) {
      setIsLoading(true);
      isFirtLoad = true;
    }
    const resp = await journeyServices.getDetailJourney(parseInt(params?.id as string));
    const data = resp?.data;
    if (resp.code == 408) {
      notification.error({
        message: data?.message || t("commonError.network"),
      });
      return;
    }
    if (resp?.status === 200) {
      const newData = {
        ...data?.data,
      };
      setDataDetail((prev) => newData);
      form.setFieldsValue({
        createBy: newData?.createBy || "--",
        createdDate: newData?.createdDate
          ? moment(newData?.createdDate).format(DATE_TIME_FORMAT_SECOND)
          : "--",
        id: newData?.id || "--",
        dateStartDelivery:
          newData?.source === SEV_CODE
            ? newData?.dateCheckin
              ? moment(newData?.dateCheckin).format(DATE_TIME_FORMAT_SECOND)
              : "--"
            : newData?.dateStartDelivery
            ? moment(newData?.dateStartDelivery).format(DATE_TIME_FORMAT_SECOND)
            : "--",
        statusStr: newData?.statusStr?.includes("SEV")
          ? newData?.statusStr.replace("SEV", "SEVT")
          : newData?.statusStr || "--",
        dateCheckin: newData?.dateCheckin
          ? moment(newData?.dateCheckin).format(DATE_TIME_FORMAT_SECOND)
          : "--",
        dateCheckout: newData?.dateCheckout
          ? moment(newData?.dateCheckout).format(DATE_TIME_FORMAT_SECOND)
          : "--",
        dateCompleteDelivery: newData?.dateCompleteDelivery
          ? moment(newData?.dateCompleteDelivery).format(DATE_TIME_FORMAT_SECOND)
          : "--",
        companyName: newData?.companyName,
        vehicleType: newData?.categoryCar?.id,
        plateNumber: newData?.plateNumber,
        optionDelivery: newData?.optionDelivery,
        driverName: newData?.driverName,
        driverIdentity: newData?.driverIdentity,
        driver: newData?.driverIdentity,
        totalDoor: newData?.totalDoor?.toString(),
        totalViolation: newData.totalViolation.toString(),
      });
    } else {
      notification.error({
        message: data?.message || t("commonError.oopsSystem"),
      });
    }
    setIsLoading(false);
  }, [form, params?.id, t]);

  useEffect(() => {
    getDataSelect();
    getDataDetail();
  }, [getDataDetail, getDataSelect]);

  // Gọi lại cứ 30s gọi lại cập nhật thông tin 1 lần
  useEffect(() => {
    const interval = setInterval(getDataDetail, 30000);
    return () => clearInterval(interval);
  }, [getDataDetail]);

  useEffect(() => {
    if (dataUpdate.listAreaOrder !== undefined) {
      for (let i = 0; i < dataUpdate.listAreaOrder.length; i++) {
        if (dataUpdate.listAreaOrder[i].dateCheckin === null) {
          setNameAreaFocus(dataUpdate.listAreaOrder[i].name);
          setCurrentAreaCheck({
            name: dataUpdate.listAreaOrder[i].name,
            action: "checkin",
          });
          break;
        } else if (dataUpdate.listAreaOrder[i].dateCheckout === null) {
          setNameAreaFocus(dataUpdate.listAreaOrder[i].name);
          setCurrentAreaCheck({
            name: dataUpdate.listAreaOrder[i].name,
            action: "checkout",
          });
          break;
        }
      }
    }
  }, [dataUpdate.currentArea]);

  const getListIMEI = useCallback(async () => {
    //select IMEI
    if (dataDetail?.source) {
      const respLock = await lockServices.getListLock({
        page: 0,
        size: 1000,
        search: JSON.stringify({ source: dataDetail?.source, isFree: 1 }),
      });
      const dataLock = respLock?.data;
      if (respLock?.status === 200) {
        setSelectIMEIData(
          dataLock?.data?.content?.map((item: ILockDetailData) => ({
            value: item?.id,
            label: item?.name + " - " + item?.uniqueid,
          })),
        );
      } else {
        setSelectIMEIData([]);
      }
    } else {
      setSelectIMEIData([]);
    }
  }, [dataDetail?.source]);

  useEffect(() => {
    getListIMEI();
  }, [getListIMEI]);

  //  set giá trị imei khóa
  useEffect(() => {
    form.setFieldValue(
      "registerCarLocks",
      Array.from({ length: totalDoorValue || 0 }, (_, i) => {
        const curItem = dataDetail?.registerCarLocks?.[i]?.categoryLock;
        const dongLapLung =
          curItem?.tcPosition?.donglaplung === 1
            ? t("lockManagementPage.lockStatus.closeBackCover")
            : curItem?.tcPosition?.donglaplung === 0
            ? t("lockManagementPage.lockStatus.openBackCover")
            : "";
        const dongdaycap =
          curItem?.tcPosition?.dongdaycap === 1
            ? t("lockManagementPage.lockStatus.closeZipper")
            : curItem?.tcPosition?.dongdaycap === 0
            ? t("lockManagementPage.lockStatus.openZipper")
            : "";
        const cutcable = curItem?.tcPosition?.cutcable
          ? t("lockManagementPage.lockStatus.brokenLockWire")
          : "";
        const curStatusLock = [dongLapLung, dongdaycap, cutcable]
          ?.filter((item) => item)
          ?.join(", ");
        return {
          lockId: curItem?.id,
          statusLock: curStatusLock,
        };
      }),
    );
  }, [dataDetail?.registerCarLocks, form, t, totalDoorValue]);

  const onFinish = async (values: any) => {
    onUpdateJourney(values);
  };

  // Cập nhật thông tin hành trình
  const onUpdateJourney = async (values: any) => {
    setIsLoadingUpdate(true);
    const driver = driverSelectData.find((value) => value.uniqueid === values.driver);
    let dataSubmit = {
      ...dataDetail,
      driverName: driver?.name,
      driverIdentity: driver?.uniqueid,
    };
    if (isCanEditInfo) {
      dataSubmit = {
        ...dataSubmit,
        companyName: values?.companyName,
        categoryCar: { id: values?.vehicleType },
        plateNumber: values?.plateNumber,
        optionDelivery: values?.optionDelivery,
        driverName: driver?.name || dataDetail?.driverName,
        driverIdentity: driver?.uniqueid || dataDetail?.driverIdentity,
        totalDoor: values?.totalDoor,
      };
    }
    if (isSEV && isCanEditDeli) {
      let isNotAllRight = false;
      if (dataDetail?.categoryCar.type !== 99) {
        dataUpdate.listAreaOrder.map((value: any, index: number) => {
          if (dataUpdate.listCDS[value.name].length === 0) {
            notification.error({
              message:
                value.name.split("/")[0] +
                ` (index ${index + 1}): ` +
                t("journeyManagementPage.validate.deliveryRequired"),
            });
            setIsLoadingUpdate(false);
            return (isNotAllRight = true);
          }
          if (
            dataUpdate.listCDS[value.name].find(
              (item: any) => item.name === "" || item.name === undefined,
            )
          ) {
            notification.error({
              message:
                value.name.split("/")[0] +
                ` (index ${index + 1}): ` +
                t("journeyManagementPage.validate.deliveryWhiteSpace"),
            });
            setIsLoadingUpdate(false);
            return (isNotAllRight = true);
          }
          if (dataUpdate.gates[value.name] === undefined) {
            notification.error({
              message:
                value.name.split("/")[0] +
                ` (index ${index + 1}): ` +
                t("journeyManagementPage.validate.gateNull"),
            });
            setIsLoadingUpdate(false);
            return (isNotAllRight = true);
          }
          if (
            dataUpdate.gates[value.name].gateIn["positionId"] === undefined ||
            dataUpdate.gates[value.name].gateIn["positionId"] === null ||
            dataUpdate.gates[value.name].gateOut["positionId"] === undefined ||
            dataUpdate.gates[value.name].gateOut["positionId"] === null
          ) {
            notification.error({
              message:
                value.name.split("/")[0] +
                ` (index ${index + 1}): ` +
                t("journeyManagementPage.validate.gateNull"),
            });
            setIsLoadingUpdate(false);
            return (isNotAllRight = true);
          }
          if (dataUpdate.listCDS[value.name].length > 1) {
            for (let i = 1; i < dataUpdate.listCDS[value.name].length; i++) {
              const element = dataUpdate.listCDS[value.name][i];
              if (element.id === dataUpdate.listCDS[value.name][i - 1].id) {
                notification.error({
                  message:
                    value.name.split("/")[0] +
                    ` (index ${index + 1}): ` +
                    t("journeyManagementPage.validate.deliveryDuplicate"),
                });
                setIsLoadingUpdate(false);
                return (isNotAllRight = true);
              }
            }
          }
        });
      }
      if (isNotAllRight) {
        return;
      }

      dataSubmit = {
        ...dataSubmit,
        registerFactoryArea: dataUpdate.listAreaOrder.map((value: any, index: number) => {
          let gateIns = [];
          let gateOuts = [];
          if (dataUpdate.gates[value.name].gateIn.positionId !== null) {
            gateIns.push({
              id: dataUpdate.gates[value.name].gateIn.id,
              categoryPosition: {
                id: dataUpdate.gates[value.name].gateIn.positionId,
              },
              orderNumber: 0,
            });
          }
          if (dataUpdate.gates[value.name].gateOut.positionId !== null) {
            gateOuts.push({
              id: dataUpdate.gates[value.name].gateOut.id,
              categoryPosition: {
                id: dataUpdate.gates[value.name].gateOut.positionId,
              },
              orderNumber: 1000,
            });
          }
          return {
            id: value.rFAId,
            factoryArea: { id: value.id },
            areaOrder: index + 1,
            companyDeliverySites: [
              ...gateIns,
              ...dataUpdate.listCDS[value.name].map((v: any, i: number) => {
                return {
                  id: v.cdsId,
                  categoryPosition: {
                    id: v.id,
                  },
                  orderNumber: i + 1,
                };
              }),
              ...gateOuts,
            ],
          };
        }),
      };
      if (dataSubmit["companyDeliverySites"] !== undefined) {
        delete dataSubmit["companyDeliverySites"];
      }
    }

    // console.log(`ListareaOrder: ${JSON.stringify(dataUpdate.listAreaOrder)}`);
    // console.log(`DataUpdate: ${JSON.stringify(dataUpdate)}`);
    console.log(
      `Datasubmit: ${JSON.stringify({ reason: reasonUpdate, registrationCar: dataSubmit })}`,
    );

    const resp = await journeyServices.updateJourney(dataDetail?.id as number, {
      reason: reasonUpdate,
      registrationCar: dataSubmit,
    });
    const data = resp?.data;
    if (resp?.status === 200) {
      setDataDetail(undefined);
      setReasonUpdate("");
      setIsEditing(false);
      setIsLoadingConfirm(false);
      setVisibleConfirm(false);
      setTypeCheckConfirm(undefined);
      getDataDetail();
      notification.success({
        message: t("journeyManagementPage.message.editJourneySuccess"),
      });
    } else {
      notification.error({
        message: data?.message || t("commonError.oopsSystem"),
      });
    }

    setIsLoadingUpdate(false);
  };

  // Gắn khoá
  const handleAttchLock = async () => {
    setIsLoadingAttachLock(true);
    const listIMEI = form.getFieldValue("registerCarLocks")?.map((item: any) => item?.lockId);
    if (listIMEI?.some((item: any) => !item)) {
      notification.error({
        message: t("journeyManagementPage.validate.IMEIRequired"),
      });
      setIsLoadingAttachLock(false);
      return;
    }
    if (listIMEI?.length > 0 && listIMEI?.length !== Array.from(new Set(listIMEI))?.length) {
      notification.error({
        message: t("journeyManagementPage.validate.IMEIDuplicate"),
      });
      setIsLoadingAttachLock(false);
      return;
    }
    const dataSubmit = {
      deviceId: listIMEI[0],
      registerCarId: dataDetail?.id,
      source: dataDetail?.source,
    };
    console.log(JSON.stringify(dataSubmit));

    const resp = await journeyServices.vendorAttachLock(dataSubmit);
    const data = resp?.data;
    if (resp?.status === 200) {
      notification.success({
        message: t("journeyManagementPage.message.attachLockSuccess"),
      });
      getDataDetail();
      window.location.reload();
    } else {
      notification.error({
        message: data?.message || t("commonError.oopsSystem"),
      });
    }
    setIsLoadingAttachLock(false);
  };

  const handleConfirm = async () => {
    setIsLoadingConfirm(true);
    let resp;
    let titleMessage = "";
    const journetId: number = dataDetail?.id as number;
    switch (typeCheckConfirm) {
      case 1: // check-in
        resp = await journeyServices.checkInJourney({
          id: journetId,
          registerFactoryAreaId: dataUpdate.currentArea.rFAId,
          gateId: dataUpdate.gates[dataUpdate.currentArea.name].gateIn.positionId,
        });
        if (resp.data.code === 200) {
          window.location.reload();
        }
        titleMessage = "journeyManagementPage.message.checkInSuccess";
        break;
      case 2: // check-out
        resp = await journeyServices.checkOutJourney({
          id: journetId,
          registerFactoryAreaId: dataUpdate.currentArea.rFAId,
          gateId: dataUpdate.gates[dataUpdate.currentArea.name].gateOut.positionId,
        });
        if (resp.data.code === 200) {
          window.location.reload();
        }
        titleMessage = "journeyManagementPage.message.checkOutSuccess";
        break;
      case 3: // xác nhận khởi hành
        resp = await journeyServices.confirmStart(journetId);
        titleMessage = "journeyManagementPage.message.confirmStartSuccess";
        break;
      case 4: // xác nhận hoàn thành
        resp = await journeyServices.completeJourney(journetId);
        titleMessage = "journeyManagementPage.message.confirmCompleteSuccess";
        break;
      case 5: // Xác nhận hủy
        if (reasonCancel === "") {
          notification.error({
            message: t("journeyManagementPage.message.errorReasonCancel"),
          });
          setIsLoadingConfirm(false);
          return;
        }
        resp = await journeyServices.cancelJourney({
          id: journetId,
          reason: reasonCancel,
        });
        titleMessage = "journeyManagementPage.message.confirmCancelSuccess";
        break;
      case 6: // Cập nhật hành trình
        if (reasonUpdate === "") {
          notification.error({
            message: t("journeyManagementPage.message.errorReasonUpdate"),
          });
          setIsLoadingConfirm(false);
          return;
        }
        form.submit();
        setIsLoadingConfirm(false);
        // setVisibleConfirm(false);
        // setTypeCheckConfirm(undefined);
        // getDataDetail();
        return;
      case 7: // Xác nhận xe di chuyển trễ
        if (reasonDelay === "") {
          notification.error({
            message: t("journeyManagementPage.message.errorReasonDelay"),
          });
          setIsLoadingConfirm(false);
          return;
        }
        resp = await journeyServices.confirmMoveDelay({
          id: journetId,
          reason: reasonDelay,
        });
        titleMessage = "journeyManagementPage.message.confirmMoveDelaySuccess";
        break;
      default:
        break;
    }
    const data = resp?.data;
    if (resp?.status === 200) {
      notification.success({
        message: t(titleMessage),
      });
      setVisibleConfirm(false);
      setTypeCheckConfirm(undefined);
      getDataDetail();
    } else {
      notification.error({
        message: data?.message || t("commonError.oopsSystem"),
      });
    }
    setIsLoadingConfirm(false);
  };

  const handleReportLock = async () => {
    setIsLoadingReportLock(true);
    if (!idLockReport) {
      notification.error({
        message: t("journeyManagementPage.validate.lockIMEIRequired"),
      });
    } else {
      const resp = await journeyServices.reportBrokenLock({
        lockId: idLockReport,
        registrationCarId: dataDetail?.id as number,
      });
      const data = resp?.data;
      if (resp?.status === 200) {
        notification.success({
          message: t("journeyManagementPage.message.reportLockSuccess"),
        });
        setVisibleReportLock(false);
        setIdLockReport(undefined);
        getDataDetail();
      } else {
        notification.error({
          message: data?.message || t("commonError.oopsSystem"),
        });
      }
    }
    setIsLoadingReportLock(false);
  };

  const handleDismantlingLock = async () => {
    setIsLoadingDismantlingLock(true);
    if (!idLockDismantling) {
      notification.error({
        message: t("journeyManagementPage.validate.lockDismantlingIMEIRequired"),
      });
    } else {
      const resp = await journeyServices.dismantlingLock({
        registerCarId: dataDetail?.id as number,
        deviceId: idLockDismantling,
        source: dataDetail?.source as string,
        action: "VENDOR_THAO_KHOA",
      });
      const data = resp?.data;
      if (resp?.status === 200) {
        notification.success({
          message: t("journeyManagementPage.message.dismantlingLockSuccess"),
        });
        setVisibleDismantlingLock(false);
        setIdLockDismantling(undefined);
        getDataDetail();
      } else {
        notification.error({
          message: data?.message || t("commonError.oopsSystem"),
        });
      }
    }
    setIsLoadingDismantlingLock(false);
  };

  // Thay đổi lý do huỷ chuyến
  const onChangeReasonCancel = (value: any) => {
    setReasonCanel(value.target.value);
  };

  // Thay đổi lý do cập nhật chuyến
  const onChangeReasonUpdate = (value: any) => {
    setReasonUpdate(value.target.value);
  };

  // Thay đổi lý do xác nhận di chuyển trễ
  const onChangeReasonMoveDelay = (value: any) => {
    setReasonDelay(value.target.value);
  };

  return (
    <DataUpdateContext.Provider value={{ dataUpdate, setDataUpdate }}>
      <div className="box-wrapper">
        <CommonForm form={form} onFinish={onFinish} layout="vertical" isLoading={isLoading}>
          {(isCanEditInfo || isCanViewDetailInfo) && (
            <div className="avic-search-box">
              <div className="advance-search-box">
                <div className="form-title">{t("journeyManagementPage.detailTitle")}</div>
                <div className="journey-info-detail">
                  <div className="info-left">
                    <Row gutter={20}>
                      <Col span={12}>
                        <CommonFormItem
                          name="createBy"
                          label={t("journeyManagementPage.label.createBy")}
                          maxLength={128}
                          disabled
                        />
                      </Col>
                      <Col span={12}>
                        <CommonFormItem
                          name="createdDate"
                          label={t("journeyManagementPage.label.createDate")}
                          maxLength={128}
                          disabled
                        />
                      </Col>
                      <Col span={12}>
                        <CommonFormItem
                          name="id"
                          label={t("journeyManagementPage.label.id")}
                          maxLength={128}
                          disabled
                        />
                      </Col>
                      {/* Ẩn thời gian vào SEV */}
                      {/* <Col span={12}>
                        <CommonFormItem
                          name="dateCheckin"
                          label={t("journeyManagementPage.label.dateCheckin")}
                          maxLength={128}
                          disabled
                        />
                      </Col> */}
                      <Col span={12}>
                        <CommonFormItem
                          name="dateStartDelivery"
                          label={t("journeyManagementPage.label.dateStartDelivery")}
                          maxLength={128}
                          disabled
                        />
                      </Col>
                      {/* Ẩn thời gian ra khỏi SEV */}
                      {/* <Col span={12}>
                        <CommonFormItem
                          name="dateCheckout"
                          label={t("journeyManagementPage.label.dateCheckout")}
                          maxLength={128}
                          disabled
                        />
                      </Col> */}
                      <Col span={12}>
                        <CommonFormItem
                          name="statusStr"
                          label={t("journeyManagementPage.label.status")}
                          maxLength={128}
                          disabled
                        />
                      </Col>
                      <Col span={12}>
                        <CommonFormItem
                          name="dateCompleteDelivery"
                          label={t("journeyManagementPage.label.dateCompleteDelivery")}
                          maxLength={128}
                          disabled
                        />
                      </Col>

                      <Form.List name="registerCarLocks">
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(({ key, name, ...restField }, index) => (
                              <Col span={24} key={key}>
                                <Row gutter={20}>
                                  <Col span={12}>
                                    <CommonFormItem
                                      {...restField}
                                      name={[name, "lockId"]}
                                      type="select"
                                      label={`${t("journeyManagementPage.label.IMEILock")} ${
                                        index + 1
                                      }`}
                                      placeholder={`${t(
                                        "journeyManagementPage.placeholder.IMEILock",
                                      )} ${index + 1}`}
                                      options={selectIMEIData.concat(
                                        dataDetail?.registerCarLocks?.map((item: any) => ({
                                          label:
                                            item?.categoryLock?.name +
                                            " - " +
                                            item?.categoryLock?.uniqueid,
                                          value: item?.categoryLock?.id,
                                        })) || [],
                                      )}
                                      showRequiredIcon={
                                        isCanAttachLock &&
                                        dataDetail?.source === profile?.source &&
                                        !dataDetail?.registerCarLocks?.length &&
                                        ((dataDetail?.status === JOURNEY_STATUS_VALUES.KHOI_TAO &&
                                          dataDetail?.source !== SEV_CODE) ||
                                          (dataDetail?.status ===
                                            JOURNEY_STATUS_VALUES.CHUA_VAO_SEV &&
                                            dataDetail?.source === SEV_CODE))
                                      }
                                      disabled={
                                        dataDetail?.categoryCar.type === 99 ||
                                        !(
                                          dataDetail?.source === profile?.source &&
                                          !dataDetail?.registerCarLocks?.length &&
                                          ((dataDetail?.status === JOURNEY_STATUS_VALUES.KHOI_TAO &&
                                            dataDetail?.source !== SEV_CODE) ||
                                            (dataDetail?.status ===
                                              JOURNEY_STATUS_VALUES.CHUA_VAO_SEV &&
                                              dataDetail?.source === SEV_CODE))
                                        ) ||
                                        !isCanAttachLock
                                      }
                                    />
                                  </Col>
                                  <Col span={12}>
                                    <CommonFormItem
                                      {...restField}
                                      name={[name, "statusLock"]}
                                      label={`${t("journeyManagementPage.label.statusLock")} ${
                                        index + 1
                                      }`}
                                      placeholder={"--"}
                                      disabled
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            ))}
                          </>
                        )}
                      </Form.List>
                    </Row>
                  </div>
                  <div className="info-right">
                    <Row gutter={20}>
                      <Col span={12}>
                        <CommonFormItem
                          name="companyName"
                          label={t("journeyManagementPage.label.companyName")}
                          placeholder={t("journeyManagementPage.placeholder.companyName") as string}
                          disabled
                        />
                      </Col>
                      <Col span={12}>
                        <CommonFormItem
                          name="vehicleType"
                          label={t("journeyManagementPage.label.vehicleType")}
                          placeholder={t("journeyManagementPage.placeholder.vehicleType") as string}
                          options={selectVehicleData}
                          type="select"
                          disabled={true}
                          // showRequiredIcon={!isDisableVehicleType}
                          // rules={
                          //   !isDisableVehicleType
                          //     ? [
                          //       {
                          //         required: true,
                          //         message: `${t("validate.select")} ${t(
                          //           "journeyManagementPage.label.vehicleType",
                          //         )}.`,
                          //       },
                          //     ]
                          //     : []
                          // }
                        />
                      </Col>
                      <Col span={12}>
                        <CommonFormItem
                          name="plateNumber"
                          label={t("journeyManagementPage.label.plateNumber")}
                          placeholder={t("journeyManagementPage.placeholder.plateNumber") as string}
                          disabled={isDisableField}
                          showRequiredIcon={!isDisableField}
                          rules={
                            !isDisableField
                              ? [
                                  {
                                    whiteSpace: true,
                                    required: true,
                                    message: `${t("validate.input")} ${t(
                                      "journeyManagementPage.label.plateNumber",
                                    )}.`,
                                  },
                                  {
                                    pattern: REGEX_CODE,
                                    message: t("journeyManagementPage.validate.plateNumber"),
                                  },
                                ]
                              : []
                          }
                        />
                      </Col>
                      <Col span={12}>
                        <CommonFormItem
                          name="optionDelivery"
                          label={t("journeyManagementPage.label.optionDelivery")}
                          placeholder={
                            t("journeyManagementPage.placeholder.optionDelivery") as string
                          }
                          options={OPTION_DELIVERY?.map((x) => ({ ...x, label: t(x?.label) }))}
                          type="select"
                          disabled={isDisableField}
                          showRequiredIcon={!isDisableField}
                          rules={
                            !isDisableField
                              ? [
                                  {
                                    required: true,
                                    message: `${t("validate.select")} ${t(
                                      "journeyManagementPage.label.optionDelivery",
                                    )}.`,
                                  },
                                ]
                              : []
                          }
                        />
                      </Col>
                      {/* <Col span={12}>
                        <CommonFormItem
                          name="driverName"
                          label={t("journeyManagementPage.label.driverName")}
                          placeholder={t("journeyManagementPage.placeholder.driverName") as string}
                          maxLength={128}
                          disabled={isDisableField}
                          showRequiredIcon={!isDisableField}
                          rules={
                            !isDisableField
                              ? [
                                {
                                  whiteSpace: true,
                                  required: true,
                                  message: `${t("validate.input")} ${t(
                                    "journeyManagementPage.label.driverName",
                                  )}.`,
                                },
                              ]
                              : []
                          }
                        />
                      </Col> */}
                      <Col span={12}>
                        <CommonFormItem
                          name="totalDoor"
                          label={t("journeyManagementPage.label.totalDoor")}
                          placeholder={t("journeyManagementPage.placeholder.totalDoor") as string}
                          maxLength={128}
                          // disabled={isDisableField}
                          disabled={true}
                          showRequiredIcon={!isDisableField}
                          rules={
                            !isDisableField
                              ? [
                                  {
                                    whiteSpace: true,
                                    required: true,
                                    message: `${t("validate.input")} ${t(
                                      "journeyManagementPage.label.totalDoor",
                                    )}.`,
                                  },
                                  {
                                    pattern: REGEX_NUMBER_GREATER_ZERO,
                                    message: `${t("journeyManagementPage.label.totalDoor")} ${t(
                                      "validate.greaterThanZore",
                                    )}.`,
                                  },
                                ]
                              : []
                          }
                        />
                      </Col>
                      {/* <Col span={12}>
                        <CommonFormItem
                          name="driverIdentity"
                          label={t("journeyManagementPage.label.driverIdentity")}
                          placeholder={
                            t("journeyManagementPage.placeholder.driverIdentity") as string
                          }
                          maxLength={12}
                          disabled={isDisableField}
                          showRequiredIcon={!isDisableField}
                          rules={
                            !isDisableField
                              ? [
                                {
                                  whiteSpace: true,
                                  required: true,
                                  message: `${t("validate.input")} ${t(
                                    "journeyManagementPage.label.driverIdentity",
                                  )}.`,
                                },
                                {
                                  pattern: REGEX_ONLY_NUMBER,
                                  message: `${t("journeyManagementPage.label.driverIdentity")} ${t(
                                    "validate.onlyNumber",
                                  )}.`,
                                },
                              ]
                              : []
                          }
                        />
                      </Col> */}
                      <Col span={12}>
                        <CommonFormItem
                          name="driver"
                          type="select"
                          label={t("journeyManagementPage.label.driver")}
                          placeholder={t("journeyManagementPage.placeholder.driver") as string}
                          maxLength={12}
                          options={driverSelectData.map((item: IDriverDetailData) => ({
                            value: item.uniqueid,
                            label: item.uniqueid + " - " + item.name,
                          }))}
                          disabled={isDisableField}
                          showRequiredIcon={!isDisableField}
                          rules={
                            !isDisableField
                              ? [
                                  {
                                    required: true,
                                    message: `${t("validate.input")} ${t(
                                      "journeyManagementPage.label.driver",
                                    )}.`,
                                  },
                                ]
                              : []
                          }
                        />
                      </Col>
                      <Col span={12}>
                        <CommonFormItem
                          name="totalViolation"
                          label={t("journeyManagementPage.label.totalViolation")}
                          placeholder={
                            t("journeyManagementPage.placeholder.totalViolation") as string
                          }
                          maxLength={128}
                          disabled={true}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="form-btn-container ">
                  <div
                    className="pdl-10"
                    style={{ width: "50%", display: "flex", justifyContent: "flex-start" }}
                  >
                    {dataDetail?.categoryCar.type !== 99 &&
                    dataDetail?.source === profile?.source &&
                    !dataDetail?.registerCarLocks?.length &&
                    isCanAttachLock &&
                    ((dataDetail?.status === JOURNEY_STATUS_VALUES.KHOI_TAO &&
                      dataDetail?.source !== SEV_CODE) ||
                      (dataDetail?.status === JOURNEY_STATUS_VALUES.CHUA_VAO_SEV &&
                        dataDetail?.source === SEV_CODE)) ? (
                      <CommonButton
                        btnType="primary"
                        size={"small"}
                        onClick={handleAttchLock}
                        loading={isLoadingAttachLock}
                      >
                        {t("common.button.attachIMEI")}
                      </CommonButton>
                    ) : (
                      <></>
                    )}
                    {!isSEV &&
                    dataDetail?.registerCarLocks?.length &&
                    dataDetail?.source === profile?.source &&
                    dataDetail?.status === JOURNEY_STATUS_VALUES.CHECK_OUT_SEV ? (
                      <CommonButton
                        btnType="primary"
                        size={"small"}
                        onClick={() => setVisibleDismantlingLock(true)}
                      >
                        {t("common.button.dismantlingLock")}
                      </CommonButton>
                    ) : (
                      <></>
                    )}
                    {isSEV &&
                    isCanReportLock &&
                    dataDetail?.registerCarLocks?.length &&
                    ((dataDetail?.source !== SEV_CODE &&
                      [
                        JOURNEY_STATUS_VALUES.CHECK_OUT_VENDOR,
                        JOURNEY_STATUS_VALUES.CHUA_VAO_SEV,
                      ].includes(dataDetail?.status as number)) ||
                      [
                        JOURNEY_STATUS_VALUES.DA_GAN_KHOA_DANG_GIAO,
                        JOURNEY_STATUS_VALUES.CHECK_OUT_SEV,
                      ].includes(dataDetail?.status as number)) ? (
                      <CommonButton
                        btnType="danger"
                        size={"small"}
                        onClick={() => setVisibleReportLock(true)}
                      >
                        {t("common.button.reportLock")}
                      </CommonButton>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div
                    className="pdr-10"
                    style={{ width: "50%", display: "flex", justifyContent: "flex-end" }}
                  >
                    {isSEV &&
                    isCanCancel &&
                    ((dataDetail?.source !== SEV_CODE &&
                      [
                        JOURNEY_STATUS_VALUES.CHECK_OUT_VENDOR,
                        JOURNEY_STATUS_VALUES.CHUA_VAO_SEV,
                      ].includes(dataDetail?.status as number)) ||
                      [
                        JOURNEY_STATUS_VALUES.DA_GAN_KHOA_DANG_GIAO,
                        JOURNEY_STATUS_VALUES.CHECK_OUT_SEV,
                      ].includes(dataDetail?.status as number)) ? (
                      <CommonButton
                        btnType="danger"
                        size={"small"}
                        onClick={() => {
                          setTypeCheckConfirm(5);
                          setVisibleConfirm(true);
                        }}
                      >
                        {t("common.button.cancelJourney")}
                      </CommonButton>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* Field chỉ để lưu giá trị từ tabs => không hiển thị */}
          <div style={{ visibility: "hidden", zIndex: -1, position: "fixed" }}>
            <CommonFormItem name="checkDuplicate" options={[]} type="select" mode="multiple" />
            <CommonFormItem
              name="companyDeliverySites"
              options={[]}
              type="select"
              mode="multiple"
            />
          </div>

          {dataDetail?.id ? (
            <div hidden={visibleConfirm}>
              <JourneyTabs detailData={dataDetail} form={form} isEditing={isEditing} />
            </div>
          ) : (
            <></>
          )}

          <Space className="form-btn-container dpl-flex justify-content-center">
            {/* button hủy/ thoát */}
            {isEditing ? (
              <CommonButton onClick={() => setIsEditing(false)} size={"large"} btnType="default">
                {t("common.button.cancel")}
              </CommonButton>
            ) : (
              <Link to={componentPath}>
                <CommonButton btnType="default" size={"large"}>
                  {t("common.button.exit")}
                </CommonButton>
              </Link>
            )}
            {/* button chỉnh sửa thông tin */}
            {(isCanEditDeli || isCanEditInfo) &&
            ((!isSEV && dataDetail?.status === JOURNEY_STATUS_VALUES.KHOI_TAO) ||
              (isSEV &&
                [
                  JOURNEY_STATUS_VALUES.CHUA_VAO_SEV,
                  JOURNEY_STATUS_VALUES.DA_GAN_KHOA_DANG_GIAO,
                ].includes(dataDetail?.status as number))) ? (
              isEditing ? (
                <CommonButton
                  btnType="primary"
                  size={"large"}
                  loading={isLoadingUpdate}
                  onClick={() => {
                    setVisibleConfirm(true);
                    setTypeCheckConfirm(6);
                  }}
                >
                  {t("common.button.update")}
                </CommonButton>
              ) : dataDetail?.status !== 1 && dataDetail?.categoryCar.type == 99 ? (
                <></>
              ) : (
                <CommonButton
                  btnType="primary"
                  size={"large"}
                  onClick={() => {
                    setIsEditing(true);
                  }}
                >
                  {t("common.button.updateInfo")}
                </CommonButton>
              )
            ) : (
              <></>
            )}
            {/* button xác nhận khởi hành */}
            {!isSEV &&
            dataDetail?.status === JOURNEY_STATUS_VALUES.DA_GAN_KHOA_CHUA_XAC_NHAN &&
            !isEditing ? (
              <CommonButton
                btnType="primary"
                size={"large"}
                onClick={() => {
                  setVisibleConfirm(true);
                  setTypeCheckConfirm(3);
                }}
              >
                {t("common.button.confirmStart")}
              </CommonButton>
            ) : (
              <></>
            )}
            {/* button xác nhận hoàn thành */}
            {dataDetail?.source === profile?.source &&
            !isEditing &&
            !dataDetail?.registerCarLocks?.length &&
            ((dataDetail?.status === JOURNEY_STATUS_VALUES.CHECK_OUT_SEV && isSEV) ||
              (dataDetail?.status === JOURNEY_STATUS_VALUES.CHECK_OUT_VENDOR && !isSEV)) ? (
              <CommonButton
                btnType="primary"
                size={"large"}
                onClick={() => {
                  setVisibleConfirm(true);
                  setTypeCheckConfirm(4);
                }}
              >
                {t("common.button.confirmComplete")}
              </CommonButton>
            ) : (
              <></>
            )}
            {/* button checkin */}
            {/* {dataDetail?.registerCarLocks?.length === dataDetail?.totalDoor &&
              dataDetail?.status === JOURNEY_STATUS_VALUES.CHUA_VAO_SEV && */}
            {dataUpdate.currentArea === undefined ? (
              <></>
            ) : (dataUpdate.currentArea.name === nameAreaFocus &&
                currentAreaCheck.action === "checkin" &&
                isSEV &&
                dataDetail?.registerCarLocks.length !== 0 &&
                !isEditing &&
                isCanCheckin) ||
              dataDetail?.categoryCar.type === 99 ? (
              dataDetail?.moveDelaySeconds !== null ? (
                <CommonButton
                  btnType="danger"
                  size={"large"}
                  onClick={() => {
                    form
                      .validateFields()
                      .then(() => {
                        setTypeCheckConfirm(7);
                        setVisibleConfirm(true);
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }}
                >
                  {t("common.button.moveDelay")}
                </CommonButton>
              ) : dataDetail?.categoryCar.type === 99 ? (
                <></>
              ) : (
                <CommonButton
                  btnType="primary"
                  size={"large"}
                  onClick={() => {
                    form
                      .validateFields()
                      .then(() => {
                        setTypeCheckConfirm(1);
                        setVisibleConfirm(true);
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }}
                >
                  {t("Checkin")}
                </CommonButton>
              )
            ) : (
              <></>
            )}
            {/* button checkout  */}
            {/* {dataDetail?.status === JOURNEY_STATUS_VALUES.DA_GAN_KHOA_DANG_GIAO && */}
            {dataUpdate.currentArea === undefined ? (
              <></>
            ) : dataUpdate.currentArea.name === nameAreaFocus &&
              currentAreaCheck.action === "checkout" &&
              dataDetail?.categoryCar.type !== 99 &&
              dataDetail?.status !== 4 &&
              dataDetail?.status !== 5 &&
              isSEV &&
              !isEditing &&
              isCanCheckin ? (
              <CommonButton
                btnType="primary"
                size={"large"}
                onClick={() => {
                  setTypeCheckConfirm(2);
                  setVisibleConfirm(true);
                }}
              >
                {t("Checkout")}
              </CommonButton>
            ) : (
              <></>
            )}
          </Space>
        </CommonForm>

        {/* check-in (1) | check-out (2) | Xác nhận khởi hành (3) | hoàn thành chuyến (4) | hủy chuyến (5) | cập nhật chuyến (6) | xác nhận đến trễ (7) */}
        {visibleConfirm && typeCheckConfirm ? (
          <CommonConfirmModal
            onCancel={() => setVisibleConfirm(false)}
            content={
              <>
                <div style={{ textAlign: "left", marginBottom: "0.5rem" }}>
                  <div className="mgt-10">
                    <span className="mgr-20">{t("journeyManagementPage.label.id")}:</span>
                    <span style={{ fontWeight: 500 }}>{dataDetail?.id || "--"}</span>
                  </div>
                  {/* <div className="mgt-10">
                    <span className="mgr-20">{t("journeyManagementPage.label.areaName")}:</span>
                    <span style={{ fontWeight: 500 }}>{dataUpdate.currentArea.name.split('/')[0] || "--"}</span>
                  </div> */}
                  <div className="mgt-10">
                    <span className="mgr-20">{t("journeyManagementPage.label.plateNumber")}:</span>
                    <span style={{ fontWeight: 500 }}>{dataDetail?.plateNumber || "--"}</span>
                  </div>
                  <div className="mgt-10">
                    <span className="mgr-20">{t("journeyManagementPage.label.driverName")}:</span>
                    <span style={{ fontWeight: 500 }}>{dataDetail?.driverName || "--"}</span>
                  </div>
                  <div className="mgt-10">
                    <span className="mgr-20">
                      {t("journeyManagementPage.label.driverIdentity")}:
                    </span>
                    <span style={{ fontWeight: 500 }}>{dataDetail?.driverIdentity || "--"}</span>
                  </div>
                  {dataDetail?.registerCarLocks?.map(
                    (item: Partial<IRegisterCarLocks>, index: number) => (
                      <div className="mgt-10" key={item?.id}>
                        <span className="mgr-20">
                          {`${t("journeyManagementPage.label.IMEILock")} ${index + 1}`}:
                        </span>
                        <span style={{ fontWeight: 500 }}>
                          {item?.categoryLock?.uniqueid || "--"}
                        </span>
                      </div>
                    ),
                  )}
                </div>
                {typeCheckConfirm === 5 ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyItems: "center",
                    }}
                  >
                    <div className="txt-base" style={{ color: "#d83939", textAlign: "center" }}>
                      {t("journeyManagementPage.label.ReasonForCancellation")}
                    </div>
                    <textarea
                      onChange={onChangeReasonCancel}
                      spellCheck={false}
                      rows={5}
                      cols={40}
                      required={true}
                      placeholder={
                        t("journeyManagementPage.placeholder.ReasonForCancellation") as string
                      }
                    />
                  </div>
                ) : (
                  <></>
                )}
                {typeCheckConfirm === 6 ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyItems: "center",
                    }}
                  >
                    <div className="txt-base" style={{ color: "#d83939", textAlign: "center" }}>
                      {t("journeyManagementPage.label.ReasonForUpdate")}
                    </div>
                    <textarea
                      onChange={onChangeReasonUpdate}
                      spellCheck={false}
                      rows={5}
                      cols={40}
                      required={true}
                      placeholder={t("journeyManagementPage.placeholder.ReasonForUpdate") as string}
                    />
                  </div>
                ) : (
                  <></>
                )}
                {typeCheckConfirm === 7 ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyItems: "center",
                    }}
                  >
                    <div
                      onClick={() => {
                        console.log(JSON.stringify(dataUpdate));
                      }}
                      className="txt-base"
                      style={{ color: "#d83939", textAlign: "center" }}
                    >
                      {t("journeyManagementPage.label.ReasonForDelay")}
                    </div>
                    <textarea
                      onChange={onChangeReasonMoveDelay}
                      spellCheck={false}
                      rows={5}
                      cols={40}
                      required={true}
                      placeholder={t("journeyManagementPage.placeholder.ReasonForDelay") as string}
                    />
                  </div>
                ) : (
                  <></>
                )}
                {typeCheckConfirm === 1 || typeCheckConfirm === 2 ? (
                  <div
                    className="mgt-10 txt-base"
                    style={{ color: "#d83939", textAlign: "center" }}
                  >
                    {t("journeyManagementPage.message.warning")}
                  </div>
                ) : (
                  <></>
                )}
              </>
            }
            title={
              t(
                typeCheckConfirm === 1
                  ? "journeyManagementPage.confirmCheckInTitle"
                  : typeCheckConfirm === 2
                  ? "journeyManagementPage.confirmCheckOutTitle"
                  : typeCheckConfirm === 3
                  ? "journeyManagementPage.confirmStartTitle"
                  : typeCheckConfirm === 4
                  ? "journeyManagementPage.confirmCompleteTitle"
                  : typeCheckConfirm === 5
                  ? "journeyManagementPage.confirmCancelTitle"
                  : typeCheckConfirm === 6
                  ? "journeyManagementPage.confirmUpdateTitle"
                  : "",
              ) as string
            }
            visible={visibleConfirm}
            onOk={handleConfirm}
            loadingBtnOk={isLoadingConfirm}
          />
        ) : (
          <></>
        )}

        {/* báo cáo khóa hỏng */}
        {visibleReportLock ? (
          <CommonConfirmModal
            onCancel={() => {
              setVisibleReportLock(false);
            }}
            content={
              <>
                <div style={{ textAlign: "left", marginBottom: "0.5rem" }}>
                  <div className="mgt-10">
                    <span className="mgr-20">
                      {t("journeyManagementPage.label.IMEILockBroken")}:
                    </span>
                    <CommonSelect
                      style={{ flex: 1, width: "9.375vw" }}
                      placeholder={t("journeyManagementPage.placeholder.IMEILockBroken") as string}
                      options={dataDetail?.registerCarLocks?.map(
                        (item: Partial<IRegisterCarLocks>) => ({
                          value: item?.categoryLock?.id,
                          label: item?.categoryLock?.uniqueid,
                        }),
                      )}
                      onChange={(value) => setIdLockReport(value)}
                      showSearch
                      allowClear
                      value={idLockReport}
                    />
                  </div>
                  <div className="mgt-10">
                    <span className="mgr-20">{t("journeyManagementPage.label.id")}:</span>
                    <span style={{ fontWeight: 500 }}>{dataDetail?.id || "--"}</span>
                  </div>
                  <div className="mgt-10">
                    <span className="mgr-20">{t("journeyManagementPage.label.plateNumber")}:</span>
                    <span style={{ fontWeight: 500 }}>{dataDetail?.plateNumber || "--"}</span>
                  </div>
                  <div className="mgt-10">
                    <span className="mgr-20">{t("journeyManagementPage.label.driverName")}:</span>
                    <span style={{ fontWeight: 500 }}>{dataDetail?.driverName || "--"}</span>
                  </div>
                  <div className="mgt-10">
                    <span className="mgr-20">
                      {t("journeyManagementPage.label.driverIdentity")}:
                    </span>
                    <span style={{ fontWeight: 500 }}>{dataDetail?.driverIdentity || "--"}</span>
                  </div>
                </div>
                <div className="mgt-10 txt-base" style={{ color: "#d83939", textAlign: "center" }}>
                  {t("journeyManagementPage.message.warningReportLock")}
                </div>
              </>
            }
            visible={visibleReportLock}
            onOk={handleReportLock}
            loadingBtnOk={isLoadingReportLock}
            isShowIcon={false}
            title={t("journeyManagementPage.confirmReportLockTitle") as string}
          />
        ) : (
          <></>
        )}

        {/* Tháo khóa */}
        {visibleDismantlingLock ? (
          <CommonConfirmModal
            onCancel={() => {
              setVisibleDismantlingLock(false);
            }}
            content={
              <>
                <div style={{ textAlign: "left", marginBottom: "0.5rem" }}>
                  <div className="mgt-10">
                    <span className="mgr-20">{t("journeyManagementPage.label.IMEILock")}:</span>
                    <CommonSelect
                      style={{ flex: 1, width: "9.375vw" }}
                      placeholder={t("journeyManagementPage.placeholder.IMEILock") as string}
                      options={dataDetail?.registerCarLocks?.map(
                        (item: Partial<IRegisterCarLocks>) => ({
                          value: item?.categoryLock?.id,
                          label: item?.categoryLock?.uniqueid,
                        }),
                      )}
                      onChange={(value) => setIdLockDismantling(value)}
                      showSearch
                      allowClear
                      value={idLockDismantling}
                    />
                  </div>
                  <div className="mgt-10">
                    <span className="mgr-20">{t("journeyManagementPage.label.id")}:</span>
                    <span style={{ fontWeight: 500 }}>{dataDetail?.id || "--"}</span>
                  </div>
                  <div className="mgt-10">
                    <span className="mgr-20">{t("journeyManagementPage.label.plateNumber")}:</span>
                    <span style={{ fontWeight: 500 }}>{dataDetail?.plateNumber || "--"}</span>
                  </div>
                  <div className="mgt-10">
                    <span className="mgr-20">{t("journeyManagementPage.label.driverName")}:</span>
                    <span style={{ fontWeight: 500 }}>{dataDetail?.driverName || "--"}</span>
                  </div>
                  <div className="mgt-10">
                    <span className="mgr-20">
                      {t("journeyManagementPage.label.driverIdentity")}:
                    </span>
                    <span style={{ fontWeight: 500 }}>{dataDetail?.driverIdentity || "--"}</span>
                  </div>
                </div>
                <div className="mgt-10 txt-base" style={{ textAlign: "center" }}>
                  {t("journeyManagementPage.message.warningDismantlingLock")}
                </div>
              </>
            }
            visible={visibleDismantlingLock}
            onOk={handleDismantlingLock}
            loadingBtnOk={isLoadingDismantlingLock}
            isShowIcon={false}
            title={t("journeyManagementPage.confirmDismantlingLockTitle") as string}
          />
        ) : (
          <></>
        )}
      </div>
    </DataUpdateContext.Provider>
  );
}

export default VehicleInfoDetail;
